import moment from "moment";

export const calendarConfig = {
	fstDayOfWk: 1, // Monday
	fstMoOfYr: 0, // Jan
};

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_START_DATE_MOMENT = moment().add(0, "days");
export const DEFAULT_END_DATE_MOMENT = moment().add(120, "days");
export const DEFAULT_START_DATE = moment()
	.add(0, "days")
	.format(DEFAULT_DATE_FORMAT);
export const DEFAULT_END_DATE = moment()
	.add(120, "days")
	.format(DEFAULT_DATE_FORMAT);
export const DEFAULT_START_DATE_MOMENT_REPORTING = moment().subtract(
	31,
	"days"
);
export const DEFAULT_END_DATE_MOMENT_REPORTING = moment().subtract(1, "days");
export const DEFAULT_START_DATE_MOMENT_DECISION_DASHBOARD = moment().subtract(
	14,
	"days"
);

export const NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER = 1;

export const singleSelectStyles = {
	control: (provided, state) => ({
		...provided,
		height: "32px",
		minHeight: "32px",
		fontSize: "0.8rem",
		border: "1px solid #cccccc",
		top: "2px",
		cursor: "pointer",
		boxShadow: "none",
		"&:hover": {
			border: "1px solid #cccccc",
		},
	}),
	valueContainer: (provided, state) => ({
		...provided,
		height: "32px",
		padding: "0 6px",
	}),
	input: (provided, state) => ({
		...provided,
		margin: "0px",
		color: "transparent",
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		height: "32px",
		// minHeight: "32px",
		padding: "6px",
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	indicatorContainer: (provided, state) => ({
		...provided,
		padding: "0px 8px",
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: "2px 4px 2px",
		fontSize: "0.8rem",
	}),
	menuList: (provided, state) => ({
		...provided,
		fontSize: "0.8rem",
		padding: 0,
	}),
};

export const screenNames = {
	decisionDashboard: "DECISION_DASHBOARD",
	decisionDashboardPromoWeekTable: "DECISION_DASHBOARD",
	decisionDashboardPromoTable: "DECISION_DASHBOARD",
	decisionDashboardEventPromoTable: "DECISION_DASHBOARD",
	decisionDashboardEventPromoWeekTable: "DECISION_DASHBOARD",
	decisionDashboardEventWeekTable: "DECISION_DASHBOARD",
	decisionDashboardEventTable: "DECISION_DASHBOARD",
	decisionDashboardPromoWeekEditTable: "DECISION_DASHBOARD",
	decisionDashboardProductDetailsTable: "DECISION_DASHBOARD",
	createEvent: "CREATE_EVENT",
	createEventBasicDetails: "CREATE_EVENT_BASIC_DETAILS",
	createEventApplicability: "CREATE_EVENT_APPLICABILITY",
	invalidStoreUploadTable: "CREATE_EVENT_APPLICABILITY",
	validStoreTable: "CREATE_EVENT_APPLICABILITY",
	eventEditValidationTable: "CREATE_EVENT_APPLICABILITY",
	eventUnadvBlockedPromoTable: "CREATE_EVENT_APPLICABILITY",
	eventEditInfoTable: "CREATE_EVENT_APPLICABILITY",
	createPromo: "CREATE_PROMO",
	createPromoSelectProducts: "CREATE_PROMO_SELECT_PRODUCTS",
	invalidSkuUploadTable: "CREATE_PROMO_SELECT_PRODUCTS",
	createPromoProductSelectionTable: "CREATE_PROMO_SELECT_PRODUCTS",
	createPromoSelectStores: "CREATE_PROMO_SELECT_STORES",
	promoWorkbenchAllPromos: "PROMO_WORKBENCH",
	marketingCalendarEventsTable: "MARKETING_CALENDAR",
	marketingCalendarPromoTable: "MARKETING_CALENDAR",
	marketingCalendar: "MARKETING_CALENDAR",
	marketingCalendarEdit: "MARKETING_CALENDAR_EDIT",
	promoSimulator: "PROMO_SIMULATOR",
	promoSimulatorDetailedMetricTable: "PROMO_SIMULATOR",
	reportingMetricsTable: "REPORTING",
	topBottomMetricsTable: "REPORTING",
	reporting: "REPORTING",
	copyPromoListTable: "MARKETING_CALENDAR",
	copyPromoValidationTable: "MARKETING_CALENDAR",
	finalizeErrorTable: "PROMO_WORKBENCH",
};

export const tableNames = {
	createPromoProductSelectionTable: "product_table",
	invalidSkuUploadTable: "invalid_sku_table",
	invalidStoreUploadTable: "invalid_store_table",
	validStoreTable: "valid_store_table",
	promoWorkbenchAllPromos: "promo_workbench_all_promo_table",
	marketingCalendarPromoTable: "marketing_calendar_promo_table",
	marketingCalendarEventsTable: "events_calendar_data",
	promoSimulator: "scenario_table",
	promoSimulatorDetailedMetricTable: "scenario_details_table",
	decisionDashboardPromoWeekTable: "decision_dashboard_promo_week_table",
	decisionDashboardPromoTable: "decision_dashboard_promo_table",
	decisionDashboardEventPromoTable: "decision_dashboard_events_promo_table",
	decisionDashboardEventPromoWeekTable:
		"decision_dashboard_events_promo_week_table",
	decisionDashboardEventWeekTable: "decision_dashboard_events_week_table",
	decisionDashboardEventTable: "decision_dashboard_events_table",
	decisionDashboardPromoWeekEditTable:
		"decision_dashboard_promo_week_edit_table",
	decisionDashboardProductDetailsTable:
		"decision_dashboard_product_details_table",
	reportingMetricsTable: "metric_table",
	topBottomMetricsTable: "top_bottom_table",
	copyPromoListTable: "copy_promo_listing_table",
	copyPromoValidationTable: "copy_promo_validation_table",
	eventEditValidationTable: "event_edit_validation_table",
	eventUnadvBlockedPromoTable: "event_edit_unadv_blocked_promo_table",
	eventEditInfoTable: "event_edit_info_table",
	finalizeErrorTable: "finalize_error_table",
};

export const DECISION_DASHBOARD = {
	AGGREGATE_METRIC_COLUMNS: [
		"actuals_markdown",
		"finalized_markdown",
		"finalized_gross_margin",
		"finalized_baseline_gross_margin",
		"finalized_incremental_gross_margin",
		"actuals_gross_margin",
		// "target_gross_margin",
		// "projected_gross_margin",
		"finalized_revenue",
		"finalized_baseline_revenue",
		"finalized_incremental_revenue",
		"actuals_revenue",
		// "target_revenue",
		// "projected_revenue",
		"finalized_sales_units",
		"finalized_baseline_sales_units",
		"finalized_incremental_sales_units",
		"actuals_sales_units",
		// "target_sales_units",
		// "projected_sales_units",
	],
	frequencyOptions: [
		{
			label: "Weekly",
			value: "weekly",
		},
		{
			label: "Monthly",
			value: "monthly",
		},
		{
			label: "Quarterly",
			value: "quarterly",
		},
	],
	defaultFrequency: {
		label: "Weekly",
		value: "weekly",
	},
	graph: {
		aggregations: {
			timeline: "weekly",
		},
		style: {
			target: {
				dashStyle: "Solid",
				color: "#F49125",
			},
			actual: {
				dashStyle: "Solid",
				color: "#65A0EF",
			},
			current_discount: {
				dashStyle: "Solid",
				color: "#8373FF",
			},
			projected: {
				dashStyle: "Solid",
				color: "#C95BAC",
			},
			finalized: {
				dashStyle: "Solid",
				color: "#13AD91",
			},
			ly: {
				dashStyle: "Solid",
				color: "#CECECE",
			},
			lly: {
				dashStyle: "Solid",
				color: "#F4E532",
			},
		},
		metrics: ["gross_margin", "revenue", "sales_units"],
		// data_types: ["target", "projected", "finalized", "actual"],
		data_types: ["finalized"],
		variance_types: [
			["planned", "current_discount"],
			["planned", "ia_recommended"],
			["planned", "finalized"],
		],
	},
	cumulative: {
		aggregations: {},
		metrics: ["gross_margin", "sales_dollars", "sales_units"],
		data_types: [
			"planned",
			"actual",
			"current_discount",
			"ia_recommended",
			"finalized",
			"ly",
			"lly",
		],
		variance_types: [
			["planned", "current_discount"],
			["planned", "ia_recommended"],
			["planned", "finalized"],
		],
	},
	table: {
		metrics: [
			"gross_margin",
			"sales_dollars",
			"sales_units",
			"aur",
			"gross_margin_percent",
			"auc",
			"msrp",
		],
		data_types: [
			"planned",
			"current_discount",
			"ia_recommended",
			"finalized",
		],
		variance_types: [
			["planned", "current_discount"],
			["planned", "ia_recommended"],
			["planned", "finalized"],
		],
	},
	eventsList: [
		{
			label: "event1",
			value: "event1",
		},
		{
			label: "event2",
			value: "event2",
		},
		{
			label: "event3",
			value: "event3",
		},
		{
			label: "event4",
			value: "event4",
		},
		{
			label: "event5",
			value: "event5",
		},
		{
			label: "event6",
			value: "event6",
		},
	],
	promotionsList: [
		{
			label: "promo1",
			value: "promo1",
		},
		{
			label: "promo2",
			value: "promo2",
		},
		{
			label: "promo3",
			value: "promo3",
		},
		{
			label: "promo4",
			value: "promo4",
		},
		{
			label: "promo5",
			value: "promo5",
		},
		{
			label: "promo6",
			value: "promo6",
		},
	],
	metricsOptions: [
		{
			label: "GM$",
			value: "gross_margin",
		},
		{
			label: "Sales $",
			value: "sales_dollars",
		},
		{
			label: "Sales Unit",
			value: "sales_units",
		},
	],
};

export const CREATE_PROMO = {
	promoProductSelection: [
		{
			label: "All Skus",
			value: "all",
		},
		{
			label: "Current Selection",
			value: "current",
		},
	],
	createPromoSteps: [
		{
			label: "Products",
			// title: "Select Products",
		},
		{
			label: "Store",
			// title: "Select Stores",
		},
		{
			label: "Discount Rules",
			// title: "Discount Rules",
		},
		{
			label: "Scenario",
			// title: "Create Scenario",
		},
		// {
		// 	label: "Promo Target",
		// 	title: "Set Promo Target",
		// },
	],
};

export const FILTER_ORDER = [
	"product_h1",
	"product_h2",
	"product_h3",
	"product_h4",
	"store_h1",
	"store_h2",
	"store_h3",
	"store_h4",
	"store_h5",
	"store_h6",
	"brand",
	"dateRange",
	"channelOptions",
	"adTypeOptions",
	"events",
	"promos",
];

export const PROMO_WORKBENCH = {
	promoStatusList: [
		{
			label: "Draft/Copied",
			value: 0,
		},
		{
			label: "To Finalize",
			value: 2,
		},
		{
			label: "Finalized",
			value: 4,
		},
		{
			label: "Archived",
			value: 6,
		},
	],
};

export const CREATE_EVENT = {
	createEventSteps: [
		{
			label: "Basic Event Details",
			// title: "Select Basic Event Details",
			// title: "Create Event",
		},
		{
			label: "Event Applicability",
			// title: "Select Event Applicability",
		},
	],
	basicEventInitialState: {
		name: "",
		submit_offer_by: null,
		start_date: null,
		end_date: null,
		customer_segment: [],
		marketing_notes: "",
		event_type: [
			{
				value: "digital",
				label: "Digital",
			},
		],
		event_objective: [],
		event_objective_description: "",
		ad_type: [],
		channel_type: [],
		min_percent_value: 0,
	},
	productSettingInitialState: {
		product_h1: [],
		product_h2: [],
		brand: [],
		product_h3: [],
		product_h4: [],
	},
	storeSelectionInitialState: {
		// channel:[],
		store_h1: [],
		store_h3: [],
		store_h2: [],
		store_h5: [],
	},
	basicEventMandatoryFields: [
		"name",
		"start_date",
		"end_date",
		"marketing_notes",
		"submit_offer_by",
		"ad_type",
		"channel_type",
		"min_percent_value",
	],
	productSettingMandatoryFields: ["product_h1", "product_h2"],
	eventObjectiveList: [
		{
			label: "Traffic/Units",
			value: "traffic_units",
		},
		{
			label: "Margin",
			value: "margin",
		},
		{
			label: "Revenue",
			value: "revenue",
		},
		{
			label: "Other",
			value: "other",
		},
	],
	eventTypeOptions: [
		{
			value: "digital",
			label: "Digital",
		},
	],
};

export const MARKETING_CALENDAR = {
	calendar_frequency: [
		// {
		// 	label: "Week",
		// 	value: "week",
		// },
		{
			label: "Month",
			value: "month",
		},
		{
			label: "Quarter",
			value: "quarter",
		},
		// {
		// 	label: "Year",
		// 	value: "year",
		// },
	],
	promoStatusList: [
		{
			label: "All",
			value: "all",
		},
		{
			label: "Finalized",
			value: 4,
		},
	],
};

export const VALID_OFFER_TYPES = {
	percent_off: "% Off",
	extra_amount_off: "$ Off",
	fixed_price: "PP",
	bxgy: "BXGY",
	bundle_offer: "Bundle Offer",
	reg_price: "Reg Price",
	reg_price_cv: "Reg Price CV",
	bxgy_$: "Bundle Offer Buy",
	bmsm: "BMSM",
};

export const DISCOUNT_LEVELS = {
	overall: -200,
	product_h1: 1,
	product_h2: 2,
	product_h3: 3,
	product_h4: 4,
	product_h5: 5,
};

export const PRODUCT_HIERARCHY = [
	{
		label: "Overall",
		value: DISCOUNT_LEVELS.overall,
		hierarchy: -200,
	},
	{
		label: "Division",
		value: DISCOUNT_LEVELS["product_h1"],
		hierarchy: "product_h1",
	},
	{
		label: "Department",
		value: DISCOUNT_LEVELS["product_h2"],
		hierarchy: "product_h2",
	},
	{
		label: "Class",
		value: DISCOUNT_LEVELS["product_h3"],
		hierarchy: "product_h3",
	},
	{
		label: "Sub Class",
		value: DISCOUNT_LEVELS["product_h4"],
		hierarchy: "product_h4",
	},
	{
		label: "SKU",
		value: DISCOUNT_LEVELS["product_h5"],
		hierarchy: "product_h5",
	},
];

export const PROMO_SIMULATOR = {
	DETAILED_METRICS: ["original", "incremental", "baseline"],
};

export const MODEL_API = {
	WORKBENCH_AGGREGATE_METRICS: {
		MODEL_ID: 1,
		COLUMNS: [
			// "target_gross_margin",
			"finalized_gross_margin",
			"finalized_baseline_gross_margin",
			"finalized_incremental_gross_margin",
			"projected_gross_margin",
			// "target_revenue",
			"finalized_revenue",
			"finalized_baseline_revenue",
			"finalized_incremental_revenue",
			"projected_revenue",
			// "target_sales_units",
			"finalized_sales_units",
			"finalized_baseline_sales_units",
			"finalized_incremental_sales_units",
			"projected_sales_units",
		],
	},
	METRICS_EVENT_TIME: {
		MODEL_ID: 4,
	},
	METRICS_PROMO_TIME: {
		MODEL_ID: 3,
	},
	METRICS_BULK_PROMO_EDIT: {
		MODEL_ID: 5,
	},
	METRICS_EVENT_PRODUCTS: {
		MODEL_ID: 8,
	},
	METRICS_PROMO_PRODUCTS: {
		MODEL_ID: 7,
	},
	METRICS_EVENTS_PROMO_LIST: {
		MODEL_ID: 9,
	},
	METRICS_EVENTS_FILTERS_LIST: {
		MODEL_ID: 10,
	},
	METRICS_REPORTING: {
		MODEL_ID: 12,
	},
	METRICS_REPORTING_TOP_BOTTOM_OFFERS: {
		MODEL_ID: 11,
	},
	METRICS_REPORTING_WATERFALL: {
		MODEL_ID: 13,
	},
	METRICS_REPORTING_EVENT_FILTER: {
		MODEL_ID: 14,
	},
	METRICS_TOP_BOTTOM_OFFERS_DOWNLOAD: {
		MODEL_ID: 15,
	},
	METRICS_REPORTING_TIME_FLEXIBLE_REPORT_DOWNLOAD: {
		MODEL_ID: 26,
	},
	GLOBAL_CONFIG_OPTIONS: {
		MODEL_ID: 16,
		parameters: {
			// event_ad_type: true,
			// offer_types: true,
			// event_channel: true,
		},
	},
	SAP_DOWNLOAD: {
		DOWNLOAD_1: 17,
		DOWNLOAD_2: 20,
		DOWNLOAD_3: 22,
		DOWNLOAD_4: 25,
	},
};

export const REPORTING = {
	AGGREGATIONS: [
		{
			label: "Entire Chain",
			value: "all",
		},
		{
			label: "Division",
			value: "product_h1",
		},
		{
			label: "Department",
			value: "product_h2",
		},
		{
			label: "Class",
			value: "product_h3",
		},
		{
			label: "Sub Class",
			value: "product_h4",
		},
	],
};

export const ACTION_MAPPING = {
	1: "create_promo",
	2: "edit_promo",
	3: "delete_promo",
	4: "download",
	5: "upload",
	6: "view_promo",
	7: "approve_promo",
	8: "finalize_promo",
	9: "create_event",
	10: "edit_event",
	11: "delete_event",
	12: "lock_event",
	13: "view_event",
	14: "download_marketing_report",
	15: "download_signage_report",
	16: "download_sap_report",
	17: "bulk_resimulate",
	18: "markdown"
};

export const ROLE_ACTION_MAPPING = {
	1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
	2: [4, 6, 9, 10, 11, 12, 13],
	3: [1, 2, 3, 4, 5, 6, 7, 8, 13],
	4: [4, 6, 13],
};
export const SUPPORT = "/support";

export const CONFLICT_TYPES = {
	0: "IN FINALIZED",
	1: "IN REQUEST",
};

export const STORE_HIERARCHY_MAPPING = {
	store_h0: "Country",
	store_h1: "DC",
	store_h2: "Region",
	store_h3: "State",
	store_h4: "District",
	store_h5: "City",
	store_h6: "Location",
};
